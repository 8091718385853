import type { Node } from '@meterup/command';
import { useCommand, useRegisterCommands } from '@meterup/command';
import {
  checkDefinedOrThrow,
  DetailPageLayouts,
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
} from '@meterup/common';
import {
  BasicSelect,
  BasicSelectItem,
  Body2,
  CopyCapsule,
  Heading2,
  Icon,
  Tab,
  Tabs,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import { fetchCompanyJSON } from '../api/company_api';
import { fetchControllerJSON, fetchControllersWithStats } from '../api/controllers_api';
import { Nav } from '../components/Nav';
import { paths } from '../constants';
import { useCanUseConfigAPIForController } from '../hooks/useCanUseConfigAPIForController';
import { useIsPathActiveFn } from '../hooks/useIsPathActiveFn';
import { colors, fontWeights, styled } from '../stitches';
import { formatLifecycleStatus } from '../utils/lifecycle_status';
import { makeLink } from '../utils/makeLink';
import { ControllerStatusIcon } from './ControllerStatusIcon';

const StyledIcon = styled(Icon, { color: colors['gray-500'], width: '$8', height: '$8' });

const CompanyLink = styled(ReactRouterLink, Body2, {
  color: colors['blue-600'],
  fontWeight: fontWeights.medium,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ControllerAddress = styled(Body2, {
  color: colors['gray-600'],
});

export const ControllerPageHeader = () => {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const isPathActive = useIsPathActiveFn();

  const controllerData = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(controllerData);

  const companyData = useQuery(
    ['companies', controllerName],
    () => fetchCompanyJSON(controllerData.company_slug),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(controllerData.company_slug),
    },
  ).data;

  const controllers =
    useQuery(['controllers', 'with-stats'], () => fetchControllersWithStats(), {
      suspense: true,
    }).data ?? [];

  const companyControllers = controllers
    .filter((c) => c.controller.company_slug === controllerData.company_slug)
    .sort((a, b) => a.controller.address.localeCompare(b.controller.address));

  const canShowConfigUI = useCanUseConfigAPIForController(controllerName);

  const navigate = useNavigate();

  const { state } = useCommand();

  const commands = useMemo(() => {
    const nodes = [
      state.nodeFactory.action({
        id: 'details',
        label: 'View Details',
        display: 'View Details',
        group: controllerName,
        synonyms: 'go to',
        icon: 'information',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerDetails, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'access-points',
        label: 'View Access Points',
        display: 'View Access Points',
        group: controllerName,
        synonyms: 'go to',
        icon: 'accessPoint',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerDevicesList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'switches',
        label: 'View Switches',
        display: 'View Switches',
        group: controllerName,
        synonyms: 'go to',
        icon: 'switch',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerSwitchesList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'clients',
        label: 'View Clients',
        display: 'View Clients',
        group: controllerName,
        synonyms: 'go to',
        icon: 'client',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerClientsList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'ISPs',
        label: 'View ISPs',
        display: 'View ISPs',
        group: controllerName,
        synonyms: 'go to',
        icon: 'wired',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerServicePlans, { controllerName }));
        },
      }),
      canShowConfigUI &&
        state.nodeFactory.action({
          id: 'logs',
          label: 'View Logs',
          display: 'View Logs',
          synonyms: 'config go to',
          group: controllerName,
          icon: 'log',
          onSelect() {
            navigate(makeLink(paths.pages.ControllerLogs, { controllerName }));
          },
        }),
      canShowConfigUI &&
        state.nodeFactory.action({
          id: 'stats',
          label: 'View Stats',
          display: 'View Stats',
          synonyms: 'config go to',
          group: controllerName,
          icon: 'reporting',
          onSelect() {
            navigate(makeLink(paths.pages.ControllerStats, { controllerName }));
          },
        }),
      state.nodeFactory.action({
        id: 'incidents',
        label: 'View Incidents',
        display: 'View Incidents',
        group: controllerName,
        synonyms: 'go to',
        icon: 'warning',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerIncidentsList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'vpn',
        label: 'View VPN',
        display: 'View VPN',
        group: controllerName,
        synonyms: 'go to',
        icon: 'secure',
        onSelect() {
          navigate(makeLink(paths.pages.VPNListPage, { controllerName }));
        },
      }),
    ].filter(Boolean) as Node[];
    return nodes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerName]);

  useRegisterCommands(commands);

  return (
    <DetailPageLayouts.Container>
      {(isDefined(companyData) || isDefinedAndNotEmpty(controllerData.address)) && (
        <DetailPageLayouts.ControllerEphemera>
          {isDefined(companyData) && (
            <CompanyLink
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, {
                  companyName: controllerData.company_slug,
                }),
              })}
            >
              {companyData.name}
            </CompanyLink>
          )}
          {(isDefined(companyData) || isDefinedAndNotEmpty(controllerData.address)) && (
            <StyledIcon icon="chevronRight" />
          )}
          {isDefinedAndNotEmpty(controllerData.address) &&
            (companyControllers.length > 1 ? (
              <BasicSelect
                aria-label="Select controller"
                value={controllerData.name}
                controlSize="small"
                onValueChange={(value: string) =>
                  navigate(makeLink(paths.pages.ControllerDetails, { controllerName: value }))
                }
              >
                {companyControllers.map((c) => (
                  <BasicSelectItem key={c.controller.name} value={c.controller.name}>
                    {c.controller.lifecycle_status !==
                      api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN &&
                      `[${formatLifecycleStatus(c.controller.lifecycle_status)}] `}
                    {c.controller.address}
                    {' - '}
                    {c.controller.name}
                  </BasicSelectItem>
                ))}
              </BasicSelect>
            ) : (
              <ControllerAddress>
                {controllerData.address}{' '}
                {controllerData.lifecycle_status !== api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN &&
                  `(${formatLifecycleStatus(controllerData.lifecycle_status)})`}
              </ControllerAddress>
            ))}
        </DetailPageLayouts.ControllerEphemera>
      )}

      <DetailPageLayouts.HeaderContent>
        <ReactRouterLink to={makeLink(paths.pages.ControllerDetails, { controllerName })}>
          <ControllerStatusIcon value={controllerData.status} />
        </ReactRouterLink>
        <DetailPageLayouts.HeadingAndTabs>
          <Heading2>
            <CopyCapsule
              textValue={controllerName}
              aria-label="Copy controller name"
              arrangement="leading-label"
            >
              {controllerName}
            </CopyCapsule>
          </Heading2>
          <Tabs>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDetails, { controllerName })}
              active={isPathActive(paths.pages.ControllerDetails, { end: true })}
              icon="information"
            >
              Details
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDevicesList, { controllerName })}
              active={isPathActive(paths.pages.ControllerDevicesList)}
              icon="device"
            >
              Access points
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerSwitchesList, { controllerName })}
              active={isPathActive(paths.pages.ControllerSwitchesList)}
              icon="switch"
            >
              Switches
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerClientsList, { controllerName })}
              active={isPathActive(paths.pages.ControllerClientsList)}
              icon="client"
            >
              Clients
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerServicePlans, { controllerName })}
              active={isPathActive(paths.pages.ControllerServicePlans)}
              icon="wired"
            >
              ISPs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerLogs, { controllerName })}
              active={isPathActive(paths.pages.ControllerLogs)}
              icon="log"
            >
              Logs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerStats, { controllerName })}
              active={isPathActive(paths.pages.ControllerStats)}
              icon="reporting"
            >
              Stats
            </Tab>
            {canShowConfigUI && (
              <>
                <Tab
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.ControllerConfig, { controllerName })}
                  active={isPathActive(paths.pages.ControllerConfig)}
                  icon="wrench"
                >
                  Config
                </Tab>
                <Tab
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.ControllerState, { controllerName })}
                  active={isPathActive(paths.pages.ControllerState)}
                  icon="pulse"
                >
                  State
                </Tab>
              </>
            )}
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerIncidentsList, { controllerName })}
              active={isPathActive(paths.pages.ControllerIncidentsList)}
              icon="warning"
            >
              Incidents
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VPNListPage, { controllerName })}
              active={isPathActive(paths.pages.VPNListPage)}
              icon="secure"
            >
              VPN
            </Tab>
          </Tabs>
        </DetailPageLayouts.HeadingAndTabs>
      </DetailPageLayouts.HeaderContent>
    </DetailPageLayouts.Container>
  );
};
