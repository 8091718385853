import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  formatDataRateBits,
  isDefined,
  isDefinedAndNotEmpty,
  kilobitsPerSecond,
  OnlineOfflineStatusBadge,
  ResourceNotFoundError,
  SignalStrengthBadge,
  UnknownBadge,
  ValueOrUnknownBadge,
} from '@meterup/common';
import {
  Badge,
  BodyMono2,
  Button,
  CollapsibleList,
  CollapsibleListContent,
  CollapsibleListItemHeader,
  CopyCapsule,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  ListItemLabel,
  ListItemNavigationArrow,
  ListItemPair,
  ListItemValue,
  ListTitle,
} from '@meterup/metric';
import oui from 'oui';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as ReactRouterLink } from 'react-router-dom';

import { fetchClientHistoryJSON } from '../../../../api/clients_api';
import { fetchDevices } from '../../../../api/devices_api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { DistanceToNowTimestamp } from '../../../../components/timestamps';
import { paths } from '../../../../constants';
import { isLastSeenKnown, isWireless } from '../../../../utils/clients';
import { getGrafanaURL } from '../../../../utils/grafana';
import { makeLink } from '../../../../utils/makeLink';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/clients/:mac',
});

export default function ClientDetail() {
  const { controllerName, mac } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientDetail),
  );

  const clientHistory =
    useQuery(
      ['controller', controllerName, 'clients', mac],
      () => fetchClientHistoryJSON(controllerName, mac),
      { suspense: true },
    ).data ?? [];

  const firstEntry = clientHistory[0];

  expectDefinedOrThrow(firstEntry, new ResourceNotFoundError(`Client ${mac} not found`));

  const devices = useQuery(
    ['controller', controllerName, 'devices', firstEntry.apname],
    () => fetchDevices(controllerName),
    {
      suspense: true,
    },
  ).data;

  const accessPoint = devices?.access_points[firstEntry.apname]?.access_point;
  const switchDevice = devices?.switches.find((s) => firstEntry.mac === s.mac);
  const ownAccessPoint = devices?.access_points[firstEntry.name]?.access_point;

  const ssids = firstEntry.ssids ?? [];

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Client</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>

      <DrawerContent>
        {isDefined(firstEntry.lease) && (
          <CollapsibleList defaultOpen>
            <CollapsibleListItemHeader>
              <ListTitle>Hardware</ListTitle>
            </CollapsibleListItemHeader>
            <CollapsibleListContent>
              <ListItemPair>
                <ListItemLabel>Manufacturer</ListItemLabel>
                <ListItemValue>{oui(firstEntry.lease.mac) ?? <UnknownBadge />}</ListItemValue>
              </ListItemPair>
              {isDefined(ownAccessPoint) && (
                <ListItemPair
                  as={ReactRouterLink}
                  to={Nav.makeTo({
                    root: makeLink(paths.pages.ControllerDevicesList, {
                      controllerName,
                    }),
                    drawer: makeLink(paths.drawers.DeviceDetail, {
                      controllerName,
                      id: ownAccessPoint.id,
                    }),
                  })}
                >
                  <ListItemLabel>Access point</ListItemLabel>
                  <ListItemValue>
                    <OnlineOfflineStatusBadge
                      value={ownAccessPoint.status}
                      icon="accessPoint"
                      arrangement="leading-icon"
                    >
                      {ownAccessPoint.location}
                    </OnlineOfflineStatusBadge>
                  </ListItemValue>
                  <ListItemNavigationArrow />
                </ListItemPair>
              )}{' '}
              <ListItemPair>
                <ListItemLabel>MAC</ListItemLabel>
                <ListItemValue as={BodyMono2}>
                  {isDefined(firstEntry.lease) ? (
                    <CopyCapsule
                      aria-label="Copy MAC address"
                      textValue={firstEntry.lease.mac}
                      arrangement="leading-icon"
                    >
                      {firstEntry.lease.mac}
                    </CopyCapsule>
                  ) : (
                    <UnknownBadge />
                  )}
                </ListItemValue>
              </ListItemPair>
              <ListItemPair>
                <ListItemLabel>Last seen</ListItemLabel>
                <ListItemValue>
                  {isLastSeenKnown(firstEntry) ? (
                    <DistanceToNowTimestamp value={firstEntry.last_seen} />
                  ) : (
                    <UnknownBadge />
                  )}
                </ListItemValue>
              </ListItemPair>
            </CollapsibleListContent>
          </CollapsibleList>
        )}
        {isDefined(switchDevice) && (
          <CollapsibleList defaultOpen>
            <CollapsibleListItemHeader>
              <ListTitle>Switch inventory info</ListTitle>
            </CollapsibleListItemHeader>
            <CollapsibleListContent>
              <ListItemPair>
                <ListItemLabel>Location</ListItemLabel>
                <ListItemValue as={BodyMono2}>
                  <CopyCapsule
                    aria-label="Copy location"
                    textValue={switchDevice.location}
                    arrangement="leading-icon"
                  >
                    {switchDevice.location}
                  </CopyCapsule>
                </ListItemValue>
              </ListItemPair>
              <ListItemPair>
                <ListItemLabel>Serial number</ListItemLabel>
                <ListItemValue as={BodyMono2}>
                  <CopyCapsule
                    aria-label="Copy serial number"
                    textValue={switchDevice.serial_number}
                    arrangement="leading-icon"
                  >
                    {switchDevice.serial_number}
                  </CopyCapsule>
                </ListItemValue>
              </ListItemPair>
              <ListItemPair>
                <ListItemLabel>Model</ListItemLabel>
                <ListItemValue as={BodyMono2}>
                  <CopyCapsule
                    aria-label="Copy model"
                    textValue={switchDevice.model}
                    arrangement="leading-icon"
                  >
                    {switchDevice.model}
                  </CopyCapsule>
                </ListItemValue>
              </ListItemPair>
              <ListItemPair>
                <ListItemLabel>Inventory status</ListItemLabel>
                <ListItemValue>
                  <ValueOrUnknownBadge value={switchDevice.inventory_status} />
                </ListItemValue>
              </ListItemPair>
            </CollapsibleListContent>
          </CollapsibleList>
        )}
        <CollapsibleList defaultOpen>
          <CollapsibleListItemHeader>
            <ListTitle>Network</ListTitle>
          </CollapsibleListItemHeader>
          <CollapsibleListContent>
            <ListItemPair>
              <ListItemLabel>Hostname</ListItemLabel>
              <ListItemValue>
                {isDefined(firstEntry.lease) ? (
                  <BodyMono2>
                    <CopyCapsule
                      aria-label="Copy hostname"
                      textValue={firstEntry.lease.name}
                      arrangement="leading-icon"
                    >
                      {firstEntry.lease.name}
                    </CopyCapsule>
                  </BodyMono2>
                ) : (
                  <UnknownBadge />
                )}
              </ListItemValue>
            </ListItemPair>
            {firstEntry.ssid.length > 0 && (
              <ListItemPair>
                <ListItemLabel>SSID</ListItemLabel>
                <ListItemValue>
                  <BodyMono2>{firstEntry.ssid}</BodyMono2>
                </ListItemValue>
              </ListItemPair>
            )}
            <ListItemPair>
              <ListItemLabel>IP</ListItemLabel>
              <ListItemValue as={BodyMono2}>
                {isDefined(firstEntry.lease) ? (
                  <CopyCapsule
                    aria-label="Copy IP address"
                    textValue={firstEntry.lease.ip}
                    arrangement="leading-icon"
                  >
                    {firstEntry.lease.ip}
                  </CopyCapsule>
                ) : (
                  <UnknownBadge />
                )}
              </ListItemValue>
            </ListItemPair>
            <ListItemPair>
              <ListItemLabel>Lease expires</ListItemLabel>
              <ListItemValue>
                {isDefined(firstEntry.lease) && isDefinedAndNotEmpty(firstEntry.lease.expiry) ? (
                  <DistanceToNowTimestamp value={firstEntry.lease.expiry} />
                ) : (
                  <UnknownBadge />
                )}
              </ListItemValue>
            </ListItemPair>
            <ListItemPair>
              <ListItemLabel>Tags</ListItemLabel>
              <ListItemValue>
                {firstEntry.tags.length > 0
                  ? firstEntry.tags.map((tag) => (
                      <span key={tag} style={{ marginLeft: 4 }}>
                        <Badge size="small">{tag}</Badge>
                      </span>
                    ))
                  : 'None'}
              </ListItemValue>
            </ListItemPair>
          </CollapsibleListContent>
        </CollapsibleList>
        {isWireless(firstEntry) && (
          <>
            <Button
              variant="tertiary"
              width="full"
              size="large"
              as="a"
              href={getGrafanaURL(controllerName, '', firstEntry.lease?.name)}
              target="_blank"
              icon="reporting"
              arrangement="leading-icon"
            >
              Open Grafana
            </Button>
            <CollapsibleList defaultOpen>
              <CollapsibleListItemHeader>
                <ListTitle>Connection</ListTitle>
              </CollapsibleListItemHeader>
              <CollapsibleListContent>
                {isDefined(accessPoint) ? (
                  <ListItemPair
                    as={ReactRouterLink}
                    to={Nav.makeTo({
                      root: makeLink(paths.pages.ControllerDevicesList, {
                        controllerName,
                      }),
                      drawer: makeLink(paths.drawers.DeviceDetail, {
                        controllerName,
                        id: accessPoint.id,
                      }),
                    })}
                  >
                    <ListItemLabel>Access point</ListItemLabel>
                    <ListItemValue>
                      <OnlineOfflineStatusBadge
                        value={accessPoint.status}
                        icon="accessPoint"
                        arrangement="leading-icon"
                      >
                        {accessPoint.location}
                      </OnlineOfflineStatusBadge>
                    </ListItemValue>
                    <ListItemNavigationArrow />
                  </ListItemPair>
                ) : (
                  <ListItemPair>
                    <ListItemLabel>Access point</ListItemLabel>
                    <ListItemValue>
                      <UnknownBadge />
                    </ListItemValue>
                  </ListItemPair>
                )}
                <ListItemPair>
                  <ListItemLabel>Channel</ListItemLabel>
                  <ListItemValue>{firstEntry.channel}</ListItemValue>
                </ListItemPair>
                <ListItemPair>
                  <ListItemLabel>Signal</ListItemLabel>
                  <ListItemValue>
                    <SignalStrengthBadge value={firstEntry.signal} />
                  </ListItemValue>
                </ListItemPair>
                <ListItemPair>
                  <ListItemLabel>Noise</ListItemLabel>
                  <ListItemValue>{firstEntry.noise} dBm</ListItemValue>
                </ListItemPair>
                <ListItemPair>
                  <ListItemLabel>Tx rate</ListItemLabel>
                  <ListItemValue>
                    {formatDataRateBits(firstEntry.tx_rate, kilobitsPerSecond)}
                  </ListItemValue>
                </ListItemPair>
                <ListItemPair>
                  <ListItemLabel>Rx rate</ListItemLabel>
                  <ListItemValue>
                    {formatDataRateBits(firstEntry.rx_rate, kilobitsPerSecond)}
                  </ListItemValue>
                </ListItemPair>
                {ssids.length > 0 && ssids[0] !== '' && (
                  <ListItemPair>
                    <ListItemLabel>SSID{ssids.length > 1 ? 's' : ''}</ListItemLabel>
                    <ListItemValue>{ssids.join(', ')}</ListItemValue>
                  </ListItemPair>
                )}
              </CollapsibleListContent>
            </CollapsibleList>
          </>
        )}
        <CollapsibleList>
          <CollapsibleListItemHeader>
            <ListTitle>History</ListTitle>
          </CollapsibleListItemHeader>
          <CollapsibleListContent>
            {clientHistory.map((entry) => (
              <ListItemPair key={entry.sid}>
                <ListItemLabel>
                  {isWireless(entry) ? entry.access_point_location ?? 'Wireless' : 'Wired'}
                </ListItemLabel>
                <ListItemValue>
                  {isDefined(entry.last_seen) && <DistanceToNowTimestamp value={entry.last_seen} />}
                </ListItemValue>
              </ListItemPair>
            ))}
          </CollapsibleListContent>
        </CollapsibleList>
      </DrawerContent>
    </Drawer>
  );
}
